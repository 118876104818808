import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import axiosConfig from "../../Service/axiosConfig"
import { useSelector, useDispatch } from "react-redux";
import { addShortlist, removeShortlist } from "../../redux/Slices/cartSlice";
import { FaCheck, FaUserCheck } from "react-icons/fa";
import { LiaUserCheckSolid } from "react-icons/lia";
import { GiCheckMark } from "react-icons/gi";

const Shortlist = (props) => {
    let { ShortlistId, setIsDeleted, updateLikesCount } = props;
    const dispatch = useDispatch();
    const { cart } = useSelector((state) => state);
    const [ShortId, setShortId] = useState(false);
    const handleLoginShow = () => {
        document.querySelector('.login-text').click();
    }
    const addToShortlist = (id) => {
        if (localStorage.getItem('token')) {
            let profile_data = JSON.parse(localStorage.getItem('profile_data'))
            axiosConfig.post(`/accounts/shortlists/`, { user: profile_data.id, profile: id })
                .then(response => {
                    dispatch(addShortlist({ ShortlistId: id, short_id: response.data.id }));
                    console.log("Product added to shortlists:", response.data);
                    console.log("wish id:", ShortId, cart.shortlists, ShortlistId)
                    updateLikesCount("like")
                })
                .catch(error => {
                    console.error("Error adding product to shortlists:", error);
                })
        } else {
            handleLoginShow()
        }
    };
    const removeFromShortlist = () => {
        let shortlists = JSON.parse(localStorage.getItem('shortlists'))
        let shortlistItem = shortlists.find((p) => p.id === ShortlistId);
        console.log(shortlistItem.short_id)
        if (shortlistItem.short_id) {
            axiosConfig.delete(`/accounts/shortlists/${shortlistItem.short_id}/`)
                .then(response => {
                    dispatch(removeShortlist({ 'ShortlistId': ShortlistId }));
                    updateLikesCount("dislike")
                    setIsDeleted(true)
                    console.log("remove product from shortlist", response)

                })
                .catch(error => {
                    console.error("Error removing product from shortlist:", error);
                })
        }

    };

    return (
        <>
            {cart.shortlists.some((p) => p.id === ShortlistId) ? (
                <div onClick={() => removeFromShortlist()} className="shortlistedbtn"><GiCheckMark className="shortlisted-check" /><p>Shortlisted</p></div>
                // <div onClick={() => removeFromShortlist()}><img src={require("../../Pages/images/shortlisted.png")} /></div>
            ) : (
                <div onClick={() => addToShortlist(ShortlistId)} className="shortlistbtn"><GiCheckMark className="shortlist-check" /><p>Shortlist</p></div>
                // <div><img src={require("../../Pages/images/shortlist.png")} /></div>
            )}
        </>
    )
}
export { Shortlist }