import React, { useState, useEffect } from "react";
import "./Post.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink } from "react-router-dom";
import { AiOutlineComment, AiOutlineSend, AiOutlineStar } from "react-icons/ai";
import { LiaUserCheckSolid } from "react-icons/lia";
import { Like } from "../Like"
import { Actions } from "./Actions";
import { PostFooter } from "./PostFooter";
import { Follow } from "../Follow";
import { BiSend, BiSolidBadgeCheck } from "react-icons/bi";
import { MdKeyboardArrowLeft, MdOutlineVerified, MdVerified } from "react-icons/md";
import { Shortlist } from "../ShortList";
import { PostPop } from "./PostPop";
import { Modal } from "react-bootstrap";
import { LikesPopup } from "./LikesPop";


const Post = (props) => {
    const { item } = props;
    const [loading, setLoading] = useState(false)
    const [likesCount, SetlikesCount] = useState(item.likes)
    const [isHidden, setIsHidden] = useState(false);
    const [smShow, setSmShow] = useState(false);
    const [commentOpen, setCommentsOpen] = useState(false)
    function updateLikesCount(action) {
        console.log("sss", action, likesCount)
        if (action === "like") {
            SetlikesCount(likesCount + 1)
        } else {
            SetlikesCount(likesCount - 1)
        }
    }
    function handleHideEvent() {
        setIsHidden(true);
    }
    if (isHidden) {
        return null;
    }
    const shareContent = async (share_to) => {
        try {
            let text = `${window.location.origin}/p/${item.uid}`
            const shareUrl = encodeURIComponent(text);

            if (share_to == "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            } else if (share_to == "fb") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            } else {
                if (navigator.share) {
                    // Use Web Share API if available
                    await navigator.share({
                        title: 'Share Post',
                        text: `${window.location.origin}/p/${item.uid}`,
                    });
                }
            }
        } catch (err) {
            console.error('Failed to share content:', err);
        }
    };
    const commentsClose = () => {
        setCommentsOpen(false)
    }
    const handleLoginShow = () => {
        document.querySelector('.login-text').click();
    }
    const OpenPopUp = () => {
        setSmShow(true)
    }
    const commentsOpen = () => {
        if (localStorage.getItem('token')) {
            setCommentsOpen(true)
        } else {
            handleLoginShow();
        }
    }
    function timeAgo(dateString) {
        const now = new Date();
        const pastDate = new Date(dateString);
        const secondsAgo = Math.floor((now - pastDate) / 1000);
        const minutesAgo = Math.floor(secondsAgo / 60);
        const hoursAgo = Math.floor(minutesAgo / 60);
        const daysAgo = Math.floor(hoursAgo / 24);
        if (daysAgo > 0) return `${daysAgo}day${daysAgo > 1 ? 's' : ''} ago`;
        if (hoursAgo > 0) return `${hoursAgo}hr${hoursAgo > 1 ? 's' : ''} ago`;
        if (minutesAgo > 0) return `${minutesAgo}min${minutesAgo > 1 ? 's' : ''} ago`;
        return `${secondsAgo}s`;
    }
    return (
        <>
            <div className="post-inner-container post-shadow">
                <div className="postheads">
                    <div className="profile-pic-divs">
                        <NavLink target="_blank" to={`/profile/${item.profile.hcc_id}`}><img src={item.profile.profile_pic ? item.profile.profile_pic : require("../Post/images/male.jpg")} className="profile-pic-home" alt={item?.profile?.full_name} /></NavLink>
                    </div>
                    <div className="profile-titles profile-follow">
                        <div>
                            <p><NavLink target="_blank" to={`/profile/${item.profile.hcc_id}`}>{item.profile.full_name}</NavLink>{item.is_celebrity ? <MdVerified size={12} color="#019bf9" /> : item.is_verified && <MdOutlineVerified size={12} color="#808080" />}</p>
                            <p style={{ lineHeight: "20px" }}>{item.profile.hcc_id} - {item.profile.designation}</p>
                        </div>
                        <Follow FollowId={item.profile.id} />
                    </div>
                    <p className="profile-actions">
                        <Actions item={item} handleHideEvent={handleHideEvent} />
                    </p>
                </div>
                <div className="post-image">
                    <img src={item.image} className="post-img" alt={item.profile?.full_name} />
                </div>
                <div className="post-footer-container">
                    <div className="post-footer">
                        <div className="symbols">
                            <div className="post-icons">
                                <Like productId={item.id} updateLikesCount={updateLikesCount} />
                                {likesCount && <div className="like-count" onClick={OpenPopUp}>{likesCount}</div>}
                            </div>
                            <div className="post-icons" onClick={commentsOpen}>
                                <AiOutlineComment className="post-symbol-icons" />
                                {item.comments && <div className="like-count">{item.comments}</div>}
                            </div>
                            <div className="post-icons">
                                <BiSend className="post-symbol-icons icon-rotate" onClick={() => shareContent("share")} />
                            </div>
                        </div>
                        <div className="shortlist">
                            <Shortlist ShortlistId={item.profile.id} />
                        </div>
                    </div>
                    <div>
                        <p className="post-caption"><strong>{item.profile.full_name} : </strong> {item.caption}</p>
                    </div>
                    <div className="post-comments">
                        <p className="open-comments" onClick={commentsOpen}>View all {item.comments} {item.comments === 1 ? "comment" : "comments"} </p>
                    </div>
                    <div className="post-comments" style={{ fontSize: "13px" }}>{timeAgo(item?.created_at)}</div>
                </div>
            </div>
            {smShow === true &&
                <LikesPopup item={item} smShow={smShow} setSmShow={setSmShow} />
            }
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={commentOpen} onHide={commentsClose} className="viewphoto-popup" style={{ paddingLeft: 0 }}>
                <Modal.Header className="show-only-mobile">
                    <div className="model-header-wid">
                        <div className="model-header-popup">
                            <div className="model-popup-back">
                                <MdKeyboardArrowLeft onClick={commentsClose} size={30} />
                            </div>
                            <p className="posttitle">Post</p>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <PostPop item={item} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export { Post };